import { makeStyles } from "@material-ui/core";
import { primaryColor } from "../scss/colors.scss";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    height: "96px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      height: "54px",
    },
  },
  appBarContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    flexGrow: "1",
  },
  brokerInformationSection: {
    paddingTop: "48px",
    paddingBottom: "48px",
  },
  brokerInformationContainerTitle: {
    fontWeight: "700",
    fontSize: "38px",
    lineHeight: "46px",
  },
  underlineHighlighted: {
    textDecoration: "underline",
    textDecorationColor: primaryColor,
    textDecorationThickness: "3px",
  },
  button: {
    textTransform: "none",
    padding: "14.5px 20px",
    "&:hover": {
      color: "inherit",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 16px",
    },
  },
  clientLoginButton: {
    "&:hover": {
      color: "#FFF",
    },
  },
  quickNavigationButtonsContainer: {
    display: "flex",
    padding: "16px",
    height: "80px",
    [theme.breakpoints.down("xs")]: {
      height: "54px",
      padding: "8px",
    },
  },
  footer: {
    background: "rgba(22, 32, 41, 1)",
    padding: "54px 0",
    color: "#FFF",
  },
  footerNavigation: {
    paddingTop: "54px",
    paddingBottom: "54px",
    flexGrow: "1",
    marginRight: "15%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
      paddingBottom: "0",
      marginRight: "0",
      display: "flex",
      justifyContent: "center",
      marginBottom: "24px",
      flexDirection: "column",
    },
  },
  footerLinkContainer: {
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  footerButton: {
    padding: 0,
    textTransform: "none",
    color: "#FFF",
    width: "105px",
    height: "45px",
    display: "initial",
    borderBottom: `5px solid ${primaryColor}`,
    borderRadius: 0,
    "&:hover": {
      color: "inherit",
    },
  },
  contactInformationWrapper: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  contactInformationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "10px 0",
    minWidth: "245px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  workingHours: {
    marginBottom: "10px",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "12px",
  },
  contactInformation: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  siteBuildInformation: {
    background: "rgba(5, 19, 21, 1)",
    padding: "24px 0",
  },
  companyInformation: {
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  licenseInformation: {
    color: "rgba(255, 255, 255, 1)",
  },
  siteBuiltBy: {
    color: "rgba(255, 255, 255, 1)",
    minWidth: "245px",
  },
  mobileNoPadding: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  hiddenOnMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  shownOnMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "initial",
    },
  },
  marginBottom16: {
    marginBottom: "16px",
  },
  brokerInformationContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    gap: "32px",
    [theme.breakpoints.between(980, "md")]: {
      gap: "16px",
    },
    [theme.breakpoints.down(980)]: {
      gap: "16px",
      flexDirection: "column-reverse",
      alignItems: "flex-end",
    },
  },
  brokerInformationWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    gap: "48px",
    [theme.breakpoints.down(980)]: {
      gap: "24px",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  logoButton: {
    cursor: "pointer",
  },
}));
