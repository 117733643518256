import { Auth } from "aws-amplify";

export const cognitoLogin = async (username, password) => {
  return Auth.signIn(
    username, // registerInfo.email,
    password // registerInfo.password,
  );
};

export const currentAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const getUserMFASetting = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  if (!cognitoUser) throw new Error("Cognito user not found");
  return Auth.getPreferredMFA(cognitoUser);
};

export const generate2FASecret = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  if (!cognitoUser) throw new Error("Cognito user not found");
  return new Promise((resolve, reject) => {
    cognitoUser.associateSoftwareToken({
      onFailure: (err) => {
        reject(err);
      },
      associateSecretCode: (secretCode) => {
        resolve(secretCode);
      },
    });
  });
};

export const verify2FASecret = async (token) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  if (!cognitoUser) throw new Error("Cognito user not found");
  const friendlyName =
    typeof window !== "undefined" ? window.navigator?.userAgent || "" : "";
  return new Promise((resolve, reject) => {
    cognitoUser.verifySoftwareToken(token, friendlyName, {
      onFailure: (err) => {
        reject(err);
      },
      onSuccess: (session) => {
        resolve(session);
      },
    });
  });
};

export const setUserMFASetting = async (enabled) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  if (!cognitoUser) throw new Error("Cognito user not found");
  return Auth.setPreferredMFA(
    cognitoUser,
    enabled ? "SOFTWARE_TOKEN_MFA" : "NOMFA"
  );
};

export const confirmMFASignin = async (cognitoUser, token, mfaMethod) => {
  return Auth.confirmSignIn(cognitoUser, token, mfaMethod);
};

export const isAuthenticatedUser = () => {
  return currentAuthenticatedUser()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const cognitoLogout = () => {
  return Auth.signOut();
};

export const cognitoChangePassword = (oldPassword, newPassword) => {
  return Auth.currentAuthenticatedUser().then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword);
  });
};

export const cognitoForgotPassword = (email) => {
  return Auth.forgotPassword(email);
};

export const cognitoForgotPasswordSubmit = (email, otp, newPassword) => {
  return Auth.forgotPasswordSubmit(email, otp, newPassword);
};

export const currentSession = () => {
  return Auth.currentSession();
};
