import { useCallback, useEffect, useMemo, useState } from "react";
import { add } from "date-fns";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { navigate } from "gatsby";
import CustomButton from "../custom-button";
import {
  useExternalFinanceAdapter,
  useInstitutionAccounts,
  useLatestBasiqConsentData,
} from "../../hooks";
import { EXTERNAL_FINANCE_ADAPTER } from "../../commons/enum";

const BasiqExpireNotificationDialog = () => {
  const { data: adapterType } = useExternalFinanceAdapter();
  const {
    data: userConsent,
    isLoading: isUserConsentLoading,
  } = useLatestBasiqConsentData();
  const {
    isLoading: isInsitutionAccountsLoading,
    data: institutionAccounts,
  } = useInstitutionAccounts();

  const isConsentExpiringSoon = useMemo(() => {
    if (!userConsent?.expiryDate) return false;
    return add(new Date(), { days: 7 }) >= new Date(userConsent.expiryDate);
  }, [userConsent]);

  const isConnectionExpiringSoon = useMemo(() => {
    if (!institutionAccounts?.length) return false;
    return institutionAccounts.some((account) => account?.isAllowToUpdate);
  }, [institutionAccounts]);

  const title = useMemo(() => {
    if (isConsentExpiringSoon) return "Extend your consent";
    if (isConnectionExpiringSoon) return "Re-authorise your connections";
    return "";
  }, [isConsentExpiringSoon, isConnectionExpiringSoon]);

  const content = useMemo(() => {
    if (isConsentExpiringSoon)
      return "Your consent is expiring, please extend it to keep your data up to date";
    if (isConnectionExpiringSoon)
      return "Your connections are expiring, please re-authorise them to keep your data up to date";
    return "";
  }, [isConsentExpiringSoon, isConnectionExpiringSoon]);

  const [isOpened, setIsOpened] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleExtend = useCallback(() => {
    navigate("/account#linked-bank-setting");
    setOpen(false);
  }, []);

  useEffect(() => {
    if (adapterType !== EXTERNAL_FINANCE_ADAPTER.BasicQ) return;
    // open once only
    if (isOpened) return;

    if (isInsitutionAccountsLoading || isUserConsentLoading) return;

    if (isConsentExpiringSoon || isConnectionExpiringSoon) {
      setIsOpened(true);
      setOpen(true);
    }
  }, [
    isInsitutionAccountsLoading,
    isUserConsentLoading,
    isOpened,
    adapterType,
  ]);

  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby="basiq-expiring-notification-dialog-aria-label"
      className="basiq-expiring-noti-dialog"
      open={open}
    >
      <DialogTitle id="basiq-expiring-noti-dialog__title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="basiq-expiring-noti-dialog__description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="basiq-expiring-noti-dialog__button-group">
        <CustomButton
          label="I will do it later"
          onClick={() => handleClose()}
          buttonType="secondary"
        />
        <CustomButton label="Extend now" onClick={() => handleExtend()} />
      </DialogActions>
    </Dialog>
  );
};

export default BasiqExpireNotificationDialog;
