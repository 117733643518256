import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-numberformat/polyfill";
import { useLocation } from "@reach/router";
import Amplify from "aws-amplify";
import { useSnackbar } from "notistack";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { IntlProvider } from "react-intl";
import { IntercomProvider } from "react-use-intercom";
import axiosClient, { checkIsPrivateRoute } from "../../api/axiosClient";
import { getMeApi } from "../../api/users.ts";
import {
  AUTHENTICATED_REDIRECT_PAGES,
  INITIAL_APP_STATE,
  NOT_DASHBOARD_ROUTES,
} from "../../commons/enum";
import { APP_LAYOUT_TYPES } from "../../commons/layout-types.constants";
import CheckUserLoggedInContext from "../../context/check-user-logged-in-context";
import DataLoadingContext from "../../context/data-loading-context";
import { FinanceContext } from "../../context/finance-context";
import JointAccountContext from "../../context/joint-account-context";
import {
  setQueryDataForDemo,
  updateThemeAfterSetMockupData,
} from "../../demo/demo-utils/build-demo-data";
import { useCompanyInfo, useIsTablet } from "../../hooks/common.hooks";
import {
  useSelectedScenarioIdCorrection,
  useSortedScenarioList,
} from "../../hooks/scenario.hooks";
import { useIsLoggedIn, useMyInfo } from "../../hooks/user.hooks.ts";
import "../../scss/account.scss";
import "../../scss/application-process.scss";
import "../../scss/chart.scss";
import "../../scss/finance.scss";
import "../../scss/help-support.scss";
import "../../scss/layout.scss";
import "../../scss/loan-application.scss";
import "../../scss/login.scss";
import "../../scss/normalize.css";
import "../../scss/sharing-referral.scss";
import "../../scss/utilities.scss";

import {
  MobileMessageBridge,
  MobileMessageType,
  mobileMessageBridge,
  parseQueryParams,
} from "../../utils";
import {
  getCsrfToken,
  logout,
  updateUserLastLoginTime,
} from "../../utils/auth";
import {
  checkIsDemoClient,
  getValueFromURLWithKey,
} from "../../utils/get-params-value";
import { queryClient } from "../../utils/react-query-util";
import {
  currentAuthenticatedUser,
  currentSession,
} from "../../utils/user-auth-provider";
import SEO from "../SEO";
import { AuthenticatedRedirect } from "../authenticated-redirect";
import CustomButton from "../custom-button";
import Footer from "../footer";
import { HideInEmbedMode } from "../hide-in-embed-mode";
import { LoadingBackdrop } from "../loading-backdrop";
import MenuLeft from "../menu_left";
import SharingLayout from "../sharing-layout";
import SignupBanner from "../signup-banner-left";
import { DashboardLogicWrapper } from "./dashboard-logic-wrapper";
import LoanApplicationLayout from "../../pages/loan-application/components/layout";
import { SIGN_UP_PROCESS_STEP } from "../../commons/signup.constants";
import BasiqExpireNotificationDialog from "./basiq-expiring-notification-dialog";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID,
  },
});

const getStatusDataReducer = (state, action) => {
  switch (action.type) {
    case "appState":
      return { ...state, ...action.value };
    default:
      throw new Error("Unexpected action");
  }
};

const initializeApp = async (location) => {
  const isDemoClient = checkIsDemoClient();
  const DEFAULT_INITIALIZATION_RESULT = {
    viewAsUserId: "",
    canViewAsUser: false,
    shouldGoToLogin: false,
  };
  let initializationResult = DEFAULT_INITIALIZATION_RESULT;
  const viewAsUserIdInURL = getValueFromURLWithKey(
    "viewAsUserId",
    location.href
  );
  const authUserNameInURL = getValueFromURLWithKey(
    "authUserName",
    location.href
  );
  try {
    await getCsrfToken();
    const authUser = await currentAuthenticatedUser();
    const authUserName = authUser.username;
    const accessToken = authUser.signInUserSession.accessToken.jwtToken;
    axiosClient.defaults.headers.common.authentication = accessToken;

    if (viewAsUserIdInURL) {
      if (authUserNameInURL === authUserName) {
        axiosClient.defaults.headers.common["view-as-user"] = viewAsUserIdInURL;
        initializationResult = {
          viewAsUserId: viewAsUserIdInURL,
          canViewAsUser: true,
          shouldGoToLogin: false,
        };
      } else {
        initializationResult = {
          viewAsUserId: viewAsUserIdInURL,
          canViewAsUser: false,
          shouldGoToLogin: false,
        };
      }
      window.history.replaceState(null, "", "/");
    } else {
      updateUserLastLoginTime();
    }
    await queryClient.fetchQuery("myInfo", getMeApi);
  } catch (err) {
    const isInDashboardRoutes = NOT_DASHBOARD_ROUTES.every(
      (item) => location.pathname.indexOf(item) < 0
    );
    if (isInDashboardRoutes) {
      initializationResult = {
        viewAsUserId: viewAsUserIdInURL,
        canViewAsUser: false,
        shouldGoToLogin: true,
      };
    }
    if (isDemoClient) {
      return DEFAULT_INITIALIZATION_RESULT;
    }
  }
  return initializationResult;
};

const setupQueryCacheNotification = () => {
  const listenerForInvalidation = MobileMessageBridge.createListener(
    (message) => {
      if (message?.data.queryKey) {
        queryClient.invalidateQueries(message.data.queryKey);
      }
    },
    [MobileMessageType.INVALIDATE_QUERY]
  );

  mobileMessageBridge.register(listenerForInvalidation);

  return () => {
    mobileMessageBridge.unregister(listenerForInvalidation.id);
  };
};

export const Layout = ({ children, location, pageContext }) => {
  const [selectedJointAccount, setSelectedJointAccount] = useState("");
  const [appState, appStateDispatch] = useReducer(
    getStatusDataReducer,
    INITIAL_APP_STATE
  );
  const { search } = useLocation();
  const [isInitializing, setIsInitializing] = useState(true);
  const { data: userInfo = {} } = useMyInfo({ pending: isInitializing });
  const isLoggedIn = useIsLoggedIn();
  const { enqueueSnackbar } = useSnackbar();
  const { isNotConnectingToAnyBank } = appState;
  const setAppState = (data) =>
    appStateDispatch({ type: "appState", value: data });
  const [checkUserLoggedIn, setCheckUserLoggedIn] = useState(false);
  const sortedScenarioList = useSortedScenarioList(
    checkUserLoggedIn && !isNotConnectingToAnyBank
  );
  const [selectedScenarioId, setSelectedScenarioId] = useState("");
  const isTablet = useIsTablet();
  const selectedScenarioDetail = React.useMemo(() => {
    return sortedScenarioList?.find(
      (scenario) => scenario.id === selectedScenarioId
    );
  }, [sortedScenarioList, selectedScenarioId]);

  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const localStorageData = JSON.parse(
    decodeURIComponent(urlParams.get("data"))
  );

  const { isLoading } = useCompanyInfo();

  useSelectedScenarioIdCorrection(
    sortedScenarioList,
    selectedScenarioId,
    setSelectedScenarioId
  );

  const financeContextValue = React.useMemo(() => {
    return {
      selectedScenarioId,
      setSelectedScenarioId,
      selectedScenarioDetail,
    };
  }, [selectedScenarioDetail, selectedScenarioId]);

  const pathname = location.pathname.replace(/\//g, "");
  const isPageSignUpWithSelectedProcess = useMemo(() => {
    const signUpProcess = urlParams.get("signup-process");
    return (
      pathname === "signup" &&
      signUpProcess &&
      Object.values(SIGN_UP_PROCESS_STEP).includes(signUpProcess)
    );
  }, [pathname, urlParams]);

  const onResizeHandler = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const pageLayout = document.documentElement;
      const vh = window.innerHeight * 0.01;
      pageLayout.current?.style.setProperty("--vh", `${vh}px`);
    }
  };

  const [viewAsUserId, setViewAsUserId] = useState("");
  const exitViewAsUser = () => {
    window.location.reload();
  };

  const isDemoClient = checkIsDemoClient();

  useLayoutEffect(() => {
    axiosClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response) {
          if (
            error.response.status === 401 &&
            error.response.data?.message === "Unauthorized" &&
            error.config.url.indexOf("logout") < 0
          ) {
            try {
              const session = await currentSession();
              const apiConfig = {
                ...error.config,
                headers: {
                  ...error.config.headers,
                  authentication: session.accessToken.jwtToken,
                },
              };
              axiosClient.defaults.headers.common.authentication =
                session.accessToken.jwtToken;
              return axiosClient(apiConfig);
            } catch (err) {
              if (
                err === "No current user" &&
                checkIsPrivateRoute() &&
                !isDemoClient
              ) {
                logout();
              }
            }
          }
          if (
            error.response.status === 403 &&
            error.response.data?.errorCode === "CFRSTOKEN_EXPIRED"
          ) {
            const csrfToken = await getCsrfToken();
            const apiConfig = {
              ...error.config,
              headers: {
                ...error.config.headers,
                "csrf-token": csrfToken,
              },
            };
            axiosClient.defaults.headers.common["csrf-token"] = csrfToken;
            return axiosClient(apiConfig);
          }
          throw error.response;
        }
        throw error;
      }
    );
  }, [isDemoClient]);

  useLayoutEffect(() => {
    if (isDemoClient) {
      setQueryDataForDemo();
      updateThemeAfterSetMockupData();
      axiosClient.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (
            !error.config.url.includes("index") &&
            !error.config.url.includes("staffInfoByCode")
          ) {
            enqueueSnackbar("Can't do this action in Demo version", {
              variant: "error",
              persist: false,
            });
            throw new Error("Can't do this action in Demo version");
          }
        }
      );
    }
  }, [enqueueSnackbar, isDemoClient]);

  useLayoutEffect(() => {
    if (!isDemoClient && localStorageData) {
      const entries = Object.entries(localStorageData);
      entries.forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
      window.history.replaceState(null, "", "/");
    }
    let unsubscribeQueryCacheNotification;
    setIsInitializing(true);
    initializeApp(location)
      .then((initializationResult = {}) => {
        if (initializationResult.viewAsUserId) {
          if (initializationResult.canViewAsUser) {
            setViewAsUserId(initializationResult.viewAsUserId);
          } else {
            enqueueSnackbar(
              "This account is not allowed to view. Please login with the permitted account and try again",
              { variant: "warning" }
            );
          }
        }
        if (initializationResult.shouldGoToLogin) {
          window.location.href = "/login";
        }
      })
      .finally(() => {
        const queryParams = parseQueryParams(location.search);
        if (queryParams.get("embed") === "true") {
          setAppState({ isInEmbedMode: true });
          unsubscribeQueryCacheNotification = setupQueryCacheNotification();
        }
        const selectedJointAccountFromParams = queryParams.get(
          "selectedJointAccount"
        );
        if (selectedJointAccountFromParams) {
          setSelectedJointAccount(selectedJointAccountFromParams);
        }
        setIsInitializing(false);
      });

    return () => {
      unsubscribeQueryCacheNotification?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResizeHandler);
    }
  }, []);

  const renderPage = () => {
    switch (pageContext.layout) {
      case APP_LAYOUT_TYPES.LOGIN_LAYOUT: {
        const page = (
          <div className="my-account-body">
            <div className="login-wrapper">
              {!isTablet && <SignupBanner />}
              {children}
              <Footer />
            </div>
          </div>
        );
        if (
          AUTHENTICATED_REDIRECT_PAGES.includes(pathname) &&
          !isPageSignUpWithSelectedProcess
        ) {
          return (
            <AuthenticatedRedirect redirectTo="/">{page}</AuthenticatedRedirect>
          );
        }
        return page;
      }
      case APP_LAYOUT_TYPES.SHARING_LAYOUT: {
        return <SharingLayout>{children}</SharingLayout>;
      }
      case APP_LAYOUT_TYPES.ERROR_LAYOUT: {
        return children;
      }
      case APP_LAYOUT_TYPES.LOAN_APPLICATION_LAYOUT: {
        return (
          <LoanApplicationLayout location={location}>
            {children}
          </LoanApplicationLayout>
        );
      }
      case APP_LAYOUT_TYPES.DASHBOARD_LAYOUT:
      default: {
        return (
          isLoggedIn && (
            <DashboardLogicWrapper location={location}>
              {viewAsUserId && (
                <div className="view-as-user__container">
                  <div className="view-as-user__label">
                    You&apos;re watching{" "}
                    <span>
                      {userInfo?.firstName} ({userInfo?.email})
                    </span>{" "}
                    profiles
                  </div>
                  <CustomButton label="Exit view as" onClick={exitViewAsUser} />
                </div>
              )}

              <HideInEmbedMode>
                <MenuLeft location={location} />
              </HideInEmbedMode>
              <div className="my-account-body">
                {children}
                <Footer />
              </div>
              <BasiqExpireNotificationDialog />
            </DashboardLogicWrapper>
          )
        );
      }
    }
  };

  return (
    <CheckUserLoggedInContext.Provider
      value={{ checkUserLoggedIn, setCheckUserLoggedIn }}
    >
      <JointAccountContext.Provider
        value={{ selectedJointAccount, setSelectedJointAccount }}
      >
        <DataLoadingContext.Provider
          value={{
            ...appState,
            setAppState,
          }}
        >
          <FinanceContext.Provider value={financeContextValue}>
            <SEO />
            <IntlProvider defaultLocale="en" locale="en">
              <IntercomProvider appId="skt381tt">
                {isInitializing || isLoading ? (
                  <LoadingBackdrop />
                ) : (
                  renderPage()
                )}
              </IntercomProvider>
            </IntlProvider>
          </FinanceContext.Provider>
        </DataLoadingContext.Provider>
      </JointAccountContext.Provider>
    </CheckUserLoggedInContext.Provider>
  );
};
