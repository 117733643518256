import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQueries, useQuery } from "react-query";
import { endOfMonth, format, startOfMonth, sub } from "date-fns";
import keyBy from "lodash/keyBy";
import { useSnackbar } from "notistack";
import { navigate } from "gatsby";

import {
  checkIfInsitutionAccountDataIsReadyToBeRetrieved,
  cleanUpYodleeFastLinkSession,
  deleteAccount,
  deleteInstitutionAccount,
  getAccounts,
  getBalanceHistory,
  getBasiqConsentUrl,
  getCurrentExternalFinanceAdapter,
  getFinanceHistory,
  getIncomesExpensesSummaryByMonth,
  getInstitutionAccounts,
  getInstitutions,
  getLatestBasiqConsent,
} from "../api/finance-views";
import { connectUserApi } from "../api/users.ts";
import {
  ACCOUNT_CATEGORIES_BY_ACCOUNT_TYPE,
  ACCOUNT_CATEGORY,
  ACCOUNT_TYPE,
  BASIQ_CONSENT_TYPE,
  EVENT_TRACKING,
  EXTERNAL_FINANCE_ADAPTER,
  FASTLINK_PARAMS_GETTERS,
  FINANCE_HISTORY_DAY_FORMAT,
} from "../commons/enum";
import DataLoadingContext from "../context/data-loading-context";
import JointAccountContext from "../context/joint-account-context";
import {
  MobileMessageType,
  checkIsDemoClient,
  getPathAndSearch,
  mobileMessageBridge,
} from "../utils";
import { getFromToLastXDays } from "../utils/calendar-util";
import { captureException } from "../utils/error-utils";
import { trackingGoogleTagEvent } from "../utils/google-tag-manager-tracking";
import {
  useSelectedJointAccountAggregationData,
  useSelectedJointAccountBalanceHistory,
  useSelectedJointAccountBankAccountHistory,
} from "./joint-account.hooks";
import { useUpdateBorrowCapacity } from "./scenario.hooks";
import { useIsLoggedIn, useMyInfo } from "./user.hooks.ts";
import { useQueryInvalidationWithNotification } from "./util.hooks";

export const useExternalFinanceAdapter = () => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery("externalFinanceAdapter", getCurrentExternalFinanceAdapter, {
    cacheTime: 0,
    enabled: isLoggedIn,
  });
};

export const useLatestBasiqConsentData = () => {
  const { data: adapterType } = useExternalFinanceAdapter();
  return useQuery("latestBasiqConsent", getLatestBasiqConsent, {
    cacheTime: 0,
    enabled: adapterType === EXTERNAL_FINANCE_ADAPTER.BasicQ,
  });
};

export const useInvalidateQueriesAfterAddingAccount = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return () => {
    invalidateQueries("accountList");
    invalidateQueries("financeHistory");
    invalidateQueries("incomesExpensesSummaryByMonth");
    invalidateQueries("institutionAccounts");
  };
};

export const useBasiqConsent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: userInfo } = useMyInfo();
  const updateBorrowCapacity = useUpdateBorrowCapacity();

  const open = useCallback(
    async ({
      action = BASIQ_CONSENT_TYPE.CONNECT,
      onBeforeRedirect = async (state) => state,
    } = {}) => {
      if (!userInfo?.id) {
        enqueueSnackbar("Missing user info", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      const consentUrl = await getBasiqConsentUrl(action);
      const state = {
        userId: userInfo.id,
        appRoute: getPathAndSearch(),
        action,
        cancelAppRoute: "",
      };
      const overridedState = await onBeforeRedirect(state);
      const encodedState = encodeURIComponent(JSON.stringify(overridedState));

      const redirectUrl = new URL(consentUrl);
      redirectUrl.searchParams.set("state", encodedState);

      window.location = redirectUrl.toString();
    },
    [enqueueSnackbar, userInfo]
  );

  const handleRedirect = useCallback(
    (appRoute) => {
      trackingGoogleTagEvent(EVENT_TRACKING.LINK_ACCOUNT, userInfo);
      updateBorrowCapacity.mutate();

      if (!appRoute) {
        navigate("/");
        return;
      }
      navigate(appRoute);
    },
    [updateBorrowCapacity, userInfo]
  );

  return {
    open,
    handleRedirect,
  };
};

export const useYodleeConnect = () => {
  const { setAppState, isInEmbedMode } = useContext(DataLoadingContext);
  const { data: userInfo } = useMyInfo();
  const invalidateQueriesAfterAddingAccount = useInvalidateQueriesAfterAddingAccount();
  const updateBorrowCapacity = useUpdateBorrowCapacity();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uiShown, setUIShown] = useState(false);
  const connectSuccessfullyRef = useRef(false);

  const defaultTargetElementIdRef = useRef(Math.random().toString());

  const defaultOnSuccess = () => {
    connectSuccessfullyRef.current = true;
  };
  const defaultOnError = (errorData) => {
    captureException(errorData);
    enqueueSnackbar("Failed to connect bank account. Please try again", {
      variant: "error",
    });
  };

  const defaultOnClose = async (closeData) => {
    setUIShown(false);
    if (connectSuccessfullyRef.current) {
      const { sites = [] } = closeData;
      const connectedSite = sites?.[0];
      if (connectedSite) {
        const snackBar = enqueueSnackbar(
          "Calculated successfully. Your data will be refreshed after a few seconds",
          { variant: "success", persist: true }
        );
        try {
          const ready = await checkIfInsitutionAccountDataIsReadyToBeRetrieved({
            institutionAccountId: connectedSite.providerAccountId,
            additionalStatus: connectedSite.additionalStatus,
          });
          if (ready) {
            invalidateQueriesAfterAddingAccount();
            trackingGoogleTagEvent(EVENT_TRACKING.LINK_ACCOUNT, userInfo);
            setAppState({
              shouldCreatePendingScenario: true,
              isNotConnectingToAnyBank: false,
            });
            updateBorrowCapacity.mutate();
            closeSnackbar(snackBar);
          } else {
            throw new Error(
              `(Connect bank) Institution account data is not ready - connectedSite ${JSON.stringify(
                connectedSite
              )}`
            );
          }
        } catch (err) {
          captureException(err);
          closeSnackbar(snackBar);
          enqueueSnackbar(
            "Something went wrong, please try to reconnect your bank account",
            { variant: "error" }
          );
        }
      }
    }
  };

  return {
    connect: ({
      targetElementId = defaultTargetElementIdRef.current,
      onSuccess = defaultOnSuccess,
      onError = defaultOnError,
      onClose = defaultOnClose,
      configParams = FASTLINK_PARAMS_GETTERS.AGGREGATION(),
    } = {}) => {
      if (isInEmbedMode) {
        mobileMessageBridge.serializeAndEmit({
          type: MobileMessageType.OPEN_FASTLINK,
          data: configParams,
        });
        return;
      }
      setUIShown(true);
      connectUserApi()
        .then((userConnectionResponse) => {
          connectSuccessfullyRef.current = false;
          const {
            userAccessKey,
            clientConnectUrl,
            clientLogoutUrl,
            clientConfigName,
          } = userConnectionResponse;
          window.fastlink.open(
            {
              fastLinkURL: clientConnectUrl,
              accessToken: `Bearer ${userAccessKey}`,
              forceIframe: true,
              params: { configName: clientConfigName, ...configParams },
              onSuccess,
              onError,
              onClose: (data) => {
                cleanUpYodleeFastLinkSession(clientLogoutUrl);
                onClose(data);
              },
            },
            targetElementId
          );
        })
        .catch((error) => {
          captureException(error);
          setUIShown(false);
          if (!checkIsDemoClient()) {
            enqueueSnackbar("Something went wrong. Please try again", {
              variant: "error",
              persist: false,
            });
          }
        });
    },
    uiShown,
    setUIShown,
    connectSuccessfullyRef,
    targetElementIdRef: defaultTargetElementIdRef,
  };
};

export const usePersonalAccountList = () => {
  const { data: myInfo = {} } = useMyInfo();
  const { state = "" } = myInfo;
  return useQuery("accountList", getAccounts, {
    enabled: state === "VERIFIED",
  });
};

export const useAccountList = () => {
  const { setAppState, isNotConnectingToAnyBank } = useContext(
    DataLoadingContext
  );
  const {
    data: jointAccountAggregationData,
  } = useSelectedJointAccountAggregationData();
  const { data: personalBankaccountData } = usePersonalAccountList();
  const bankAccountData = useMemo(() => {
    return jointAccountAggregationData?.bankAccounts || personalBankaccountData;
  }, [jointAccountAggregationData?.bankAccounts, personalBankaccountData]);

  useEffect(() => {
    if (bankAccountData?.data?.length === 0 && !isNotConnectingToAnyBank) {
      setAppState({ isNotConnectingToAnyBank: true });
    }
    if (bankAccountData?.data?.length > 0 && isNotConnectingToAnyBank) {
      setAppState({ isNotConnectingToAnyBank: false });
    }
  }, [bankAccountData?.data?.length, isNotConnectingToAnyBank, setAppState]);

  return { data: bankAccountData };
};

export const useAccountListByInstitutionAccountId = (institutionAccountId) => {
  const params = {
    institutionAccountId,
  };
  const { data: myInfo = {} } = useMyInfo();
  const { state = "" } = myInfo;
  return useQuery(
    ["accountList", institutionAccountId],
    () => getAccounts(params),
    {
      enabled: state === "VERIFIED",
    }
  );
};

export const useIsNotConnectingToAnyBank = () => {
  const { isNotConnectingToAnyBank } = useContext(DataLoadingContext);
  return isNotConnectingToAnyBank;
};

export const useFinanceHistory = (financeHistoryParams) => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useQuery(
    ["financeHistory", financeHistoryParams],
    () => getFinanceHistory(financeHistoryParams),
    { enabled: !selectedJointAccount }
  );
};

export const useLastXDaysFinanceHistory = ({
  accountId = "",
  numberOfDays,
  externalId = "",
  includeTransactions = false,
  from,
  to,
}) => {
  const { from: fromDate, to: toDate } = getFromToLastXDays(numberOfDays);
  const financeHistoryParams = {
    accountId,
    externalId,
    from: format(from || fromDate, FINANCE_HISTORY_DAY_FORMAT),
    to: format(to || toDate, FINANCE_HISTORY_DAY_FORMAT),
    includeTransactions,
  };
  const jointAccountAggregationData = useSelectedJointAccountBankAccountHistory(
    financeHistoryParams
  );
  const financeHistory = useFinanceHistory(financeHistoryParams);
  if (jointAccountAggregationData.data) {
    return {
      ...jointAccountAggregationData,
      data: jointAccountAggregationData?.data,
    };
  }
  return financeHistory;
};

export const useIncomesExpensesSummaryByMonth = ({
  from,
  to,
  numberOfLookbackMonths,
}) => {
  return useQuery(
    ["incomesExpensesSummaryByMonth", from, to, numberOfLookbackMonths],
    () => getIncomesExpensesSummaryByMonth({ from, to, numberOfLookbackMonths })
  );
};

/**
 *
 * untilCurrentDate: if false, income/expsense data will be fetched until the end of the last month
 * otherwise, it will be fetched until the current date
 */
export const useLastXMonthsIncomesExpensesSummary = ({
  numberOfMonths,
  numberOfLookbackMonths = 3,
  untilCurrentDate = false,
}) => {
  const endOfLastMonth = endOfMonth(sub(new Date(), { months: 1 }));
  const fromDate = startOfMonth(
    sub(endOfLastMonth, { months: numberOfMonths + numberOfLookbackMonths - 1 })
  );
  return useIncomesExpensesSummaryByMonth({
    from: format(fromDate, FINANCE_HISTORY_DAY_FORMAT),
    to: format(
      untilCurrentDate ? new Date() : endOfLastMonth,
      FINANCE_HISTORY_DAY_FORMAT
    ),
    numberOfLookbackMonths,
  });
};

export const useIndividualBalanceHistories = ({
  accountIds = [],
  from,
  to,
  interval = "M",
}) => {
  const { selectedJointAccount = "" } = useContext(JointAccountContext);
  return useQueries(
    accountIds.map((accountId) => ({
      queryKey: ["balanceHistory", accountId, from, to, interval],
      queryFn: () => getBalanceHistory({ accountId, from, to, interval }),
      select: (response) => ({
        accountId: accountId.toString(),
        balanceHistory: response.data,
      }),
      enabled: !selectedJointAccount,
    }))
  );
};
export const useBalanceHistories = (balanceHistoriesParams) => {
  const formattedBalanceHistoriesParams = {
    ...balanceHistoriesParams,
    from: format(balanceHistoriesParams.from, FINANCE_HISTORY_DAY_FORMAT),
    to: format(balanceHistoriesParams.to, FINANCE_HISTORY_DAY_FORMAT),
  };
  const jointAccountAggregationData = useSelectedJointAccountBalanceHistory(
    formattedBalanceHistoriesParams
  );

  const balanceHistories = useIndividualBalanceHistories(
    formattedBalanceHistoriesParams
  );

  if (jointAccountAggregationData.data) {
    return jointAccountAggregationData;
  }
  return balanceHistories;
};

export const useIsGettingFinance = () => {
  const { isLoading: isLoadingFinanceHistory } = useLastXDaysFinanceHistory({
    numberOfDays: 30,
  });
  const numberOfMonthsHavePassed = new Date().getMonth();
  const {
    isLoading: isLoadingIncomesExpensesSummary,
  } = useLastXMonthsIncomesExpensesSummary({
    numberOfMonths: numberOfMonthsHavePassed,
    numberOfLookbackMonths: 2,
  });
  return isLoadingFinanceHistory || isLoadingIncomesExpensesSummary;
};

export const useInstitutionList = () => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery("institutionList", getInstitutions, {
    enabled: isLoggedIn,
  });
};

export const useInstitutionAccounts = () => {
  return useQuery("institutionAccounts", getInstitutionAccounts, {
    select: ({ data }) => data,
  });
};

const useActionAfterUnlinkAccount = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const invalidateQueriesAfterAddingAccount = useInvalidateQueriesAfterAddingAccount();
  return ({ shouldUpdateProperty = false }) => {
    invalidateQueriesAfterAddingAccount();
    if (shouldUpdateProperty) {
      invalidateQueries("propertyList");
    }
  };
};

export const useUnlinkInstitutionAccount = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const actionAfterUnlinkAccount = useActionAfterUnlinkAccount();
  return useMutation(deleteInstitutionAccount, {
    onSuccess: (result = {}) => {
      const { shouldUpdateProperty = false } = result;
      actionAfterUnlinkAccount({ shouldUpdateProperty });
      invalidateQueries("institutionAccounts");
    },
  });
};
export const useUnlinkAccount = () => {
  const actionAfterUnlinkAccount = useActionAfterUnlinkAccount();
  return useMutation(deleteAccount, {
    onSuccess: (result = {}) => {
      const { shouldUpdateProperty = false } = result;
      actionAfterUnlinkAccount({ shouldUpdateProperty });
    },
  });
};

export const useDetailAccountList = () => {
  const { data: accountList, isLoading, isIdle, isFetching } = useAccountList();
  const { data: institutionList } = useInstitutionList();
  return useMemo(() => {
    const accountListData = accountList?.data || [];
    const institutionListData = institutionList?.data || [];
    const institutionMap = keyBy(institutionListData, "id");
    return {
      data: accountListData.map((account) => ({
        ...account,
        institutionDetail: institutionMap[account.institution],
      })),
      isLoading: isLoading || isFetching,
      isIdle,
    };
  }, [accountList?.data, institutionList?.data, isIdle, isLoading, isFetching]);
};

export const useLinkedBankAccountData = () => {
  const { data: accountList } = useDetailAccountList();
  return useMemo(() => {
    if (!accountList || accountList?.length === 0) {
      return undefined;
    }

    return accountList.reduce(
      (result, account) => {
        const accountType = account.class?.type || ACCOUNT_TYPE.UNKNOWN;
        const { institutionDetail } = account;
        const preparedAccount = {
          ...account,
          accountId: account.id,
          balance: account.balance,
          institutionLogo:
            institutionDetail?.favicon || institutionDetail?.logo || "",
          name: account.name || "",
          type: accountType,
        };

        const accountCategories =
          ACCOUNT_CATEGORIES_BY_ACCOUNT_TYPE[accountType];

        if (accountCategories && accountCategories.length > 0) {
          accountCategories.forEach((category) => {
            result[category].push(preparedAccount);
          });
        }

        if (account.isAsset) {
          result[ACCOUNT_CATEGORY.ASSETS].push(preparedAccount);
        }

        if (
          account.isAsset &&
          accountType !== ACCOUNT_TYPE.SHARE &&
          accountType !== ACCOUNT_TYPE.SUPERANNUATION
        ) {
          result[ACCOUNT_CATEGORY.ASSETS_NOT_SHARES_AND_SUPERS].push(
            preparedAccount
          );
        }

        if (!accountCategories && !account.isAsset) {
          result[ACCOUNT_CATEGORY.OTHERS].push(preparedAccount);
        }

        return result;
      },
      {
        [ACCOUNT_CATEGORY.ASSETS]: [],
        [ACCOUNT_CATEGORY.ASSETS_NOT_SHARES_AND_SUPERS]: [],
        [ACCOUNT_CATEGORY.CREDIT]: [],
        [ACCOUNT_CATEGORY.LOAN]: [],
        [ACCOUNT_CATEGORY.PROPERTY_LOAN]: [],
        [ACCOUNT_CATEGORY.PERSONAL_LOAN]: [],
        [ACCOUNT_CATEGORY.SHARE]: [],
        [ACCOUNT_CATEGORY.SUPER]: [],
        [ACCOUNT_CATEGORY.OTHERS]: [],
      }
    );
  }, [accountList]);
};

export const useIsNotConnectingToAnyShareOrSuper = () => {
  const linkedBankAccountData = useLinkedBankAccountData();
  return useMemo(() => {
    return !(
      linkedBankAccountData?.[ACCOUNT_CATEGORY.SHARE]?.length ||
      linkedBankAccountData?.[ACCOUNT_CATEGORY.SUPER]?.length
    );
  }, [linkedBankAccountData]);
};
