export const extractUserReferralFromUrl = (url = "") => {
  const pageUrl = url;
  const locationPath = pageUrl.split("/");

  // remove '/referral' in pathname
  const referralInformation = locationPath.splice(2);
  const [referralName = "", referralCode = ""] = referralInformation;
  return [referralName, referralCode];
};

export const extractBrokerReferralFromUrl = (url = "") => {
  const pageUrl = url;
  const locationPath = pageUrl.split("/");
  const isBrokerPage = locationPath[1] === "staff";
  if (isBrokerPage) {
    const referralInformation = locationPath.splice(2);
    const [staffCode = ""] = referralInformation;
    return [isBrokerPage, staffCode];
  }
  return [isBrokerPage, ""];
};

export const getPathAndSearch = () => {
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    return `${url.pathname}${url.search}`;
  }
  return undefined;
};

export const setQueryParamToPathAndSearch = (pathAndSearch, key, value) => {
  const [path, search] = pathAndSearch.split("?");
  const searchParams = new URLSearchParams(search);
  searchParams.set(key, value);
  return `${path}?${searchParams.toString()}`;
};

export const getCurrentHostname = () => {
  return typeof window !== "undefined" && window.location.hostname;
};

export const getCurrentPath = () => {
  return typeof window !== "undefined" && window.location.pathname;
};
export const getCurrentLocation = () => {
  return typeof window !== "undefined" && window.location;
};

export const getcompanyDomain = () => {
  if (typeof window !== "undefined") {
    if (window.location.hostname.includes("experity")) {
      return "experity";
    }
    return window.location.hostname.split(".")[0];
  }
  return "";
};

export const checkIsDemoClient = () => {
  const companyDomain = getcompanyDomain();
  return companyDomain === "demo";
};

export const getValueFromURLWithKey = (key, url = "") => {
  const pageUrl = url;
  const match = pageUrl.match(`[?&]${key}=([^&]+)`);
  return match ? match[1] : null;
};

export const parseQueryParams = (locationSearch = "") => {
  return new URLSearchParams(locationSearch);
};
