import {
  BASIQ_CONSENT_TYPE,
  INSTITUTION_ACCOUNT_ADDITIONAL_STATUS,
} from "../commons/enum";
import { captureMessage } from "../utils/error-utils";
import { timeout } from "../utils/miscellaneous-util";
import axiosClient from "./axiosClient";
import { get } from "./non-xhr-service";
import { connectUserApi } from "./users.ts";

const getInstitutions = () => {
  const url = "/finance-views/institutions";
  return axiosClient.get(url);
};

const getInstitutionAccounts = () => {
  const url = "/finance-views/institutionAccounts";
  return axiosClient.get(url);
};

const getInstitutionAccount = (institutionAccountId) => {
  const url = `/finance-views/institutionAccounts/${institutionAccountId}`;
  return axiosClient.get(url);
};

const deleteInstitutionAccount = (institutionAccountId) => {
  const url = `/finance-views/institutionAccounts/${institutionAccountId}`;
  return axiosClient.delete(url);
};
const deleteAccount = (accountId) => {
  const url = `/finance-views/accounts/${accountId}`;
  return axiosClient.delete(url);
};

const getAccounts = ({ institutionAccountId }) => {
  const url = `/finance-views/accounts`;
  return axiosClient.get(url, { params: { institutionAccountId } });
};

const getTransactions = ({
  accountId,
  direction,
  limit,
  keyword,
  from,
  to,
}) => {
  const url = `/finance-views/transactions`;
  return axiosClient.get(url, {
    params: { accountId, direction, limit, keyword, from, to },
  });
};

const getFinanceHistory = ({
  accountId,
  from,
  to,
  includeTransactions = false,
}) => {
  const url = "/finance-views/history";
  return axiosClient.get(url, {
    params: { accountId, from, to, includeTransactions },
  });
};

const getIncomesExpensesSummaryByMonth = ({
  from,
  to,
  numberOfLookbackMonths,
}) => {
  const url = "/finance-views/incomes-expenses-summary-by-month";
  return axiosClient.get(url, {
    params: { from, to, numberOfLookbackMonths },
  });
};

const getBalanceHistory = ({ accountId, from, to, interval }) => {
  const url = "/finance-views/balance-history";
  return axiosClient.get(url, {
    params: { accountId, from, to, interval },
  });
};

const cleanUpYodleeFastLinkSession = (logoutUrl) => {
  return get(logoutUrl);
};

const POLLING_INTERVAL_IN_MILLISECONDS = 2 * 1000;
const TIMEOUT_IN_MILLISECONDS = 3 * 60 * 1000;
const checkIfInsitutionAccountDataIsReadyToBeRetrieved = async ({
  institutionAccountId,
  additionalStatus,
  maxRetries = TIMEOUT_IN_MILLISECONDS / POLLING_INTERVAL_IN_MILLISECONDS,
  currentRetry = 0,
  callback = () => {},
}) => {
  if (currentRetry >= maxRetries) {
    captureMessage(
      `(Connect bank) - Timeout while checking institution data: institutionAccountId: ${institutionAccountId}, additionalStatus: ${additionalStatus}, currentRetry: ${currentRetry}`
    );
    return false;
  }

  switch (additionalStatus) {
    case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.AVAILABLE_DATA_RETRIEVED:
    case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.PARTIAL_DATA_RETRIEVED:
      return true;
    case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.LOGIN_IN_PROGRESS:
    case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.DATA_RETRIEVAL_IN_PROGRESS:
    case INSTITUTION_ACCOUNT_ADDITIONAL_STATUS.ACCT_SUMMARY_RECEIVED: {
      await timeout(POLLING_INTERVAL_IN_MILLISECONDS);
      const institutionAccountResponse = await getInstitutionAccount(
        institutionAccountId
      );
      callback(institutionAccountResponse.data.additionalStatus);
      return checkIfInsitutionAccountDataIsReadyToBeRetrieved({
        institutionAccountId,
        additionalStatus: institutionAccountResponse.data.additionalStatus,
        maxRetries,
        currentRetry: currentRetry + 1,
        callback,
      });
    }
    default:
      captureMessage(
        `(Connect bank) Invalid additionalStatus: ${additionalStatus}`
      );
      return false;
  }
};

const getScenarioFinanceStatistic = (scenarioId) => {
  const url = `/finance-views/statistic/${scenarioId}`;
  return axiosClient.get(url);
};

const getCurrentExternalFinanceAdapter = () => {
  const url = "/finance-views/external-adapter";
  return axiosClient.get(url);
};

const getLatestBasiqConsent = () => {
  const url = "/finance-views/latest-basiq-consent";
  return axiosClient.get(url);
};

const getBasiqConsentUrl = async (action = BASIQ_CONSENT_TYPE.CONNECT) => {
  if (action !== BASIQ_CONSENT_TYPE.CONNECT) {
    const url = "/finance-views/basiq-consent-url";
    return axiosClient.get(url, {
      params: { action },
    });
  }
  const { clientConnectUrl } = await connectUserApi();
  return clientConnectUrl;
};

export {
  getInstitutions,
  getInstitutionAccounts,
  deleteInstitutionAccount,
  getTransactions,
  deleteAccount,
  getAccounts,
  getFinanceHistory,
  getIncomesExpensesSummaryByMonth,
  getBalanceHistory,
  cleanUpYodleeFastLinkSession,
  checkIfInsitutionAccountDataIsReadyToBeRetrieved,
  getScenarioFinanceStatistic,
  getCurrentExternalFinanceAdapter,
  getBasiqConsentUrl,
  getLatestBasiqConsent,
};
