import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteUser,
  getMeApi,
  getUserConfirmation,
  putMeApi,
  updateUserAssociate,
  updateUserConfirmation,
} from "../api/users";
import { ConfirmationStatus, UpdateUserInfo } from "../types/user.types";
import { setUserForErrorLogging } from "../utils/error-utils";
import {
  cognitoChangePassword,
  isAuthenticatedUser,
  getUserMFASetting,
  setUserMFASetting,
  generate2FASecret,
  verify2FASecret,
  confirmMFASignin,
} from "../utils/user-auth-provider";
import { useQueryInvalidationWithNotification } from "./util.hooks";
import { EMFAType } from "../features/account/2fa-setting";

export const useIsLoggedIn = () => {
  const { data: isLoggedIn } = useQuery("isLoggedIn", isAuthenticatedUser, {
    retry: false,
  });
  return !!isLoggedIn;
};

export const useUpdateUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(
    (updateUserInfo: UpdateUserInfo) => putMeApi(updateUserInfo),
    {
      onSuccess: () => {
        invalidateQueries("myInfo");
        enqueueSnackbar("Your information has been updated", {
          variant: "success",
        });
      },
    }
  );
};

export const useUserConfimationData = (id: string) => {
  return useQuery(["confirmationData", id], () => getUserConfirmation(id), {
    retry: 0,
  });
};

export const use2FASetting = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useQuery("twoFASetting", () => getUserMFASetting(), {
    retry: 2,
    cacheTime: 0,
    onError: (err) => {
      console.error(err);
      enqueueSnackbar("Failed to fetch 2FA setting", {
        variant: "error",
        autoHideDuration: 3000,
      });
    },
  });
};

export const useToggle2FASetting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    (toggle2FASetting: { enabled: boolean }) =>
      setUserMFASetting(toggle2FASetting.enabled),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("twoFASetting");
        enqueueSnackbar("Setup 2FA successfully", {
          variant: "success",
        });
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar("Failed to toggle 2FA setting", {
          variant: "error",
          autoHideDuration: 3000,
        });
      },
    }
  );
};

export const useGenerate2FASecret = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(() => generate2FASecret(), {
    onError: (err) => {
      console.error(err);
      enqueueSnackbar("Failed to generate 2FA QRCode", {
        variant: "error",
        autoHideDuration: 3000,
      });
    },
  });
};

export const useVerify2FASecret = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (verify2FASecretData: { token: string }) =>
      verify2FASecret(verify2FASecretData.token),
    {
      onError: (err) => {
        console.error(err);
        enqueueSnackbar("Failed to verify 2FA QRCode", {
          variant: "error",
          autoHideDuration: 3000,
        });
      },
    }
  );
};

export const useConfirmMFASignIn = () => {
  return useMutation(
    (confirmSignInData: { user: any; token: string; mfaMethod: EMFAType }) =>
      confirmMFASignin(
        confirmSignInData.user,
        confirmSignInData.token,
        confirmSignInData.mfaMethod
      )
  );
};

export const useUpdateConfirmationData = () => {
  return useMutation(
    (updateUserConfirmationData: { id: string; status: ConfirmationStatus }) =>
      updateUserConfirmation(updateUserConfirmationData.id, {
        status: updateUserConfirmationData.status,
      })
  );
};

export const useMyInfo = (options = { pending: false }) => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery("myInfo", getMeApi, {
    enabled: options.pending ? false : isLoggedIn,
    retry: false,
    onSuccess: (user) => {
      setUserForErrorLogging(user.id);
    },
  });
};

export const useUpdateUserAssociate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, params }: { id: string; params: Record<string, unknown> }) =>
      updateUserAssociate(id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("staffList");
      },
    }
  );
};

export const useClearQueryCache = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.clear();
  };
};

export const useDeleteAccount = ({ onSuccess }) => {
  return useMutation(deleteUser, {
    onSuccess,
  });
};

export const useChangePassword = ({ onSuccess, onError }) => {
  return useMutation(
    ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }) => cognitoChangePassword(currentPassword, newPassword),
    {
      onSuccess,
      onError,
    }
  );
};
