import _ from "lodash";
import { ACCOUNT_CATEGORY } from "../../commons/enum";
import { convertToFloat, convertToInt } from "../../utils/convert-number";

const sumTotalBalance = (accounts) => {
  return accounts.reduce((sumBalance, account) => {
    const { balance = 0 } = account;
    return sumBalance + convertToFloat(balance);
  }, 0);
};

const sumPropertyEstimate = (sum, property) => {
  const { estimate = 0 } = property;
  return sum + estimate;
};

const onlyNegativeFilter = (account) => {
  return convertToFloat(account.balance || 0) < 0;
};

const calculateSummaryData = (
  last30DaysFinanceHistory = {
    totalIncome: 0,
    totalExpense: 0,
  },
  linkedBankAccounts = {},
  propertyList = [],
  scenarioList = []
) => {
  const { totalIncome = 0, totalExpense = 0 } = last30DaysFinanceHistory;

  const {
    [ACCOUNT_CATEGORY.ASSETS]: assets = [],
    [ACCOUNT_CATEGORY.PROPERTY_LOAN]: propertyLoans = [],
    [ACCOUNT_CATEGORY.PERSONAL_LOAN]: personalLoans = [],
    [ACCOUNT_CATEGORY.CREDIT]: credits = [],
    [ACCOUNT_CATEGORY.SHARE]: shares = [],
    [ACCOUNT_CATEGORY.SUPER]: supers = [],
  } = linkedBankAccounts;

  const onlyNegativeCredits = credits.filter(onlyNegativeFilter);

  const borrowCapacity = _.max(
    scenarioList.map((scenario) => scenario?.buyingPower || 0)
  );

  const totalAssetsBalance = convertToInt(sumTotalBalance(assets));
  const totalDebt = sumTotalBalance(
    [].concat(personalLoans, onlyNegativeCredits)
  );
  const totalPropertyLoan = sumTotalBalance(propertyLoans);
  // credits could be both positive and negative, loans in some cases could be positive
  const liabilities =
    totalDebt + totalPropertyLoan < 0 ? totalDebt + totalPropertyLoan : 0;
  const propertyPortfolio = propertyList.reduce(sumPropertyEstimate, 0);
  const totalSharesAndSuper = sumTotalBalance([...shares, ...supers]);

  // v1 deprecated
  // const netPosition = totalAssetsBalance - liabilities;

  // v2: liabilities always <= 0
  const netPosition = totalAssetsBalance + liabilities;

  // v1 deprecated
  // const netWealth = totalSharesAndSuper + totalAssetsBalance + propertyPortfolio - liabilities;

  // v2: liabilities always <= 0, shares and supers are is already an asset if positive
  const netWealth = totalAssetsBalance + propertyPortfolio + liabilities;

  const sideBarData = {
    income: convertToInt(totalIncome),
    expenses: convertToInt(Math.abs(totalExpense)),
    netWealth: convertToInt(netWealth),
    netPosition: convertToInt(netPosition),
    cashDeposit: convertToInt(totalAssetsBalance),
    propertyPortfolio,
    borrowCapacity,
    liabilities: convertToInt(Math.abs(liabilities)),
    totalSharesAndSuper: convertToInt(totalSharesAndSuper),
  };
  return sideBarData;
};

export default calculateSummaryData;
